import React, { useCallback, useEffect, useMemo } from 'react';
import MapGl, {
  MapProps,
  MapRef,
  Layer,
  Marker,
  Source,
  FillLayerSpecification,
} from 'react-map-gl/mapbox';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useHoveredOption } from '../../contexts/hovered-option';

function metersToPixelsAtMaxZoom({
  latitude,
  meters,
}: {
  meters: number;
  latitude: number;
}) {
  return meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
}

export function generateZoomStops(radiusKm: number, latitude: number) {
  const radiusInMeters = radiusKm * 1000;

  const stopsArray: [number, number][] = [
    [0, 0],
    [20, metersToPixelsAtMaxZoom({ latitude, meters: radiusInMeters })],
  ];

  return stopsArray;
}

type MarkerRadiusProps = {
  marker: { lat: number; lng: number };
  radiusKm: number;
};

function MarkerRadius({ marker, radiusKm }: MarkerRadiusProps) {
  const zoomStops = generateZoomStops(radiusKm, marker.lat);

  const circleLayer = {
    id: 'radius-circle-fill',
    type: 'circle' as const,
    paint: {
      'circle-radius': {
        stops: zoomStops,
        base: 2,
      },
      'circle-color': '#7d52f4',
      'circle-opacity': 0.2,
      'circle-stroke-width': 2,
      'circle-stroke-color': '#7d52f4',
      'circle-stroke-opacity': 0.4,
    },
  };

  const circleGeoJson = {
    type: 'Feature' as const,
    properties: {},
    geometry: {
      type: 'Point' as const,
      coordinates: [marker.lng, marker.lat],
    },
  };

  return (
    <>
      <Source id="radius-circle" type="geojson" data={circleGeoJson}>
        <Layer {...circleLayer} />
      </Source>
      <Marker color="#7d52f4" longitude={marker.lng} latitude={marker.lat} />
    </>
  );
}

type TilesProps = {
  geoJsonData: {
    type: 'FeatureCollection';
    features: Neighbourhood[];
  };
  selectedTilesIds: string[];
};

function Tiles({ geoJsonData, selectedTilesIds }: TilesProps) {
  const { hoveredOptionValue } = useHoveredOption();

  const layerPaint = React.useMemo<FillLayerSpecification['paint']>(
    () => ({
      'fill-color': '#7d52f4',
      'fill-opacity': [
        'case',
        // First check if the feature is hovered
        ['in', ['get', 'districtCode'], ['literal', hoveredOptionValue]],
        0.8, // Hovered opacity
        // If not hovered, check if it's selected
        ['in', ['get', 'districtCode'], ['literal', selectedTilesIds]],
        0.3, // Selected opacity
        0, // Default opacity (transparent)
      ],
    }),
    [selectedTilesIds, hoveredOptionValue]
  );

  return (
    <Source id="neighbourhoods-source" type="geojson" data={geoJsonData}>
      <Layer id="neighbourhood-layer" type="fill" paint={layerPaint} />
    </Source>
  );
}

export type Neighbourhood = {
  id: number;
  geometry: {
    type: 'MultiPolygon';
    coordinates: number[][][][];
  };
  properties: {
    districtCode: string;
    districtName: string;
    municipalityCode: string;
    municipalityName: string;
    group?: string;
  };
  type: 'Feature';
};

type Props = {
  neighbourhoodsData: Neighbourhood[];
  radiusKm: number;
  marker: { lat: number; lng: number };
  cityCoordinates: { lat: number; lng: number };
  onMarkerChange: (marker: { lat: number; lng: number }) => void;
  locationSelector: 'Neighbourhood' | 'Radius' | 'Geolocation';
  locationIds: string[];
};

export function Map({
  neighbourhoodsData,
  radiusKm,
  marker,
  onMarkerChange,
  cityCoordinates,
  locationSelector,
  locationIds,
}: Props) {
  const mapRef = React.useRef<MapRef>(null);

  const [accessToken] = React.useState(
    'pk.eyJ1IjoicmVudHNsYW0iLCJhIjoiY2x1dGo1NXoxMTFjNjJrcGE3bXdsdWxlcyJ9.jdWPKv8v_Aji725YEYgVCQ'
  );

  const flyToCoordinates = useCallback(
    (coordinates: { lat: number; lng: number }) => {
      if (!mapRef.current) return;

      mapRef.current.flyTo({
        essential: true,
        center: coordinates,
        speed: 3,
        zoom: 9,
      });
    },
    []
  );

  const style: MapProps['style'] = {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  };

  const initialViewState: MapProps['initialViewState'] = {
    // Amsterdam longitude and latitude
    longitude: 4.896029,
    latitude: 52.371807,
    zoom: 9,
  };

  const geoJsonData = useMemo(
    () => ({
      features: neighbourhoodsData,
      type: 'FeatureCollection' as const,
    }),
    [neighbourhoodsData]
  );

  const renderMarker = marker && locationSelector === 'Radius';
  const renderTiles =
    locationSelector === 'Geolocation' && !!neighbourhoodsData.length;

  const handleMapClick: NonNullable<MapProps['onClick']> = ({ lngLat }) => {
    if (locationSelector !== 'Radius') return;
    onMarkerChange({ lat: lngLat.lat, lng: lngLat.lng });
  };

  useEffect(() => {
    if (!cityCoordinates.lat) return;
    if (!cityCoordinates.lng) return;

    const timeoutId = setTimeout(flyToCoordinates, 10, cityCoordinates);

    return () => clearTimeout(timeoutId);
  }, [flyToCoordinates, cityCoordinates]);

  return (
    <MapGl
      ref={mapRef}
      initialViewState={initialViewState}
      mapboxAccessToken={accessToken}
      style={style}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      onClick={handleMapClick}
    >
      {renderTiles && (
        <Tiles geoJsonData={geoJsonData} selectedTilesIds={locationIds} />
      )}
      {renderMarker && <MarkerRadius marker={marker} radiusKm={radiusKm} />}
    </MapGl>
  );
}
