import React from "react";
import DefaultTableContainer from "./../common/defaultTableContainer";
import UsersTable from "./usersTable";
import http from "../../services/httpService";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const renderCreateUser = () => {
  return (
    <Row>
      <Col className="my-3">
        <Link to={`/users/new`} className="btn btn-primary">
          Create user
        </Link>
      </Col>
    </Row>
  );
};

export class UsersList extends React.Component {
  state = {
    data: [],
    isLoading: true,
  };
  render() {
    return (
      <DefaultTableContainer>
        <UsersTable
          title="Users"
          onLoad={async () => {
            const { data } = await http.get("users");
            this.setState({ data, isLoading: false });
          }}
          data={this.state.data}
          isLoading={this.state.isLoading}
        />
        {renderCreateUser()}
      </DefaultTableContainer>
    );
  }
}
