import React from "react";
import BaseForm from "./../common/baseForm";
import BaseSelectInput from "./../common/baseSelectInput";
import UserRolesInput from "./userRolesInput";
import { Row, Col, Form, FormGroup, Input, Card, CardHeader, CardBody, } from "reactstrap";
import Joi from "joi-browser";
import http from "../../services/httpService";
import {toast} from "react-toastify";
import {NotificationMethodsInput} from "./notificationMethodsInput";

class UserForm extends BaseForm {

  state = {
    data: {},
    errors: {},
    isLoaded : false
  }
  
  schema = {
    accessFailedCount: Joi.number().required().label("accessFailedCount"),
    firstName: Joi.string().required().label("FirstName"),
    lastName: Joi.string().required().label("LastName"),
    createDate: Joi.optional(),
    cultureName: Joi.string().required().label("Culture"),
    phoneNumber: Joi.string().required().label("Phone number"),
    description: Joi.string().allow("").allow(null).optional().label("Description"),
    email: Joi.string().email(),
    emailConfirmed: Joi.boolean(),
    searchProfile: Joi.optional(),
    userSearchProfileId: Joi.optional(),
    id: Joi.string(),
    isActive: Joi.boolean(),
    lockoutEnabled: Joi.boolean(),
    lockoutEnd: Joi.string().allow(null),
    modifiedDate: Joi.optional(),
    onboardingState: Joi.optional(),
    idVerificationState: Joi.optional(),
    phoneNumberConfirmed: Joi.boolean(),
    roles: Joi.array(),
    notificationMethods: Joi.array(),
    userNotificationMethods: Joi.array().min(1),
    twoFactorEnabled: Joi.boolean(),
    userRoleNames: Joi.array().min(1),
    unsubscribedDate: Joi.optional(),
    id: Joi.optional()
  };

  async componentDidMount() {
    if (this.props.userId === "new") {
      const roles = await http.get("users/roles");
      const { data: notificationMethods } = await http.get("notificationMethods");
      const data = {
        accessFailedCount: 0,
        firstName: "",
        lastName: "",
        createDate: "",
        cultureName: "en",
        phoneNumber: "",
        description: "",
        email: "",
        emailConfirmed: false,
        searchProfile: null,
        userSearchProfileId: null,
        id: "",
        isActive: false,
        lockoutEnabled: false,
        lockoutEnd: null,
        modifiedDate: "",
        onboardingState: null,
        idVerificationState: null,
        phoneNumberConfirmed: false,
        roles: roles.data,
        notificationMethods: notificationMethods,
        userNotificationMethods: [notificationMethods.filter(nm => nm.name === "Email")[0].id],
        twoFactorEnabled: false,
        userRoleNames: ["User"],
        unsubscribedDate: null
      };
      this.setState({
        data,
        notificationMethods,
        isLoaded: true
      });
      return;
    }
    const { data } = await http.get("users/edit/" + this.props.userId);
    const { data: notificationMethods } = await http.get("notificationMethods");
    this.setState({
      data,
      notificationMethods,
      isLoaded: true
    });
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      if (this.props.userId !== "new") {
        await http.post(`users/edit/${this.props.userId}`, data);
      } else {
        await http.post("users/add", data);
      }
      toast.success("user details saved");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  }

  handleUserNotificationMethodsChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    if (input.checked) {
      data.userNotificationMethods.push(input.value);
    } else {
      data.userNotificationMethods = data.userNotificationMethods.filter(ur => ur !== input.value);
    }
    const errors = this.checkErrors({
      name: input.name,
      value: data.userNotificationMethods
    });
    this.setState({ data, errors });
  };
  handleRolesChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    if (input.checked) {
      data.userRoleNames.push(input.value);
    } else {
      data.userRoleNames = data.userRoleNames.filter(ur => ur !== input.value);
    }
    const errors = this.checkErrors({
      name: input.name,
      value: data.userRoleNames
    });
    this.setState({ data, errors });
  };
  render() {
    if (!this.state.isLoaded) return null;

    const {
      firstName,
      lastName,
        id,
      roles,
      userRoleNames,
      createDate,
        cultureName,
      modifiedDate,
      onboardingState,
      idVerificationState,
      unsubscribedDate,
        userNotificationMethods
    } = this.state.data;

    const fullName = firstName + " " + lastName;
    return (
        <Form onSubmit={this.handleSubmit} className="mt-4">
          <Card>
            <Row>
              <Col>
                <CardHeader>
                  <strong>{fullName}</strong>
                </CardHeader>
              </Col>
            </Row>
            <CardBody>
              <Row>
                <Col sm="9">
                  <Input hidden value={id} readOnly />
                  <Row>
                    <Col>
                      <FormGroup check className="mb-3">
                        {this.renderCheckbox("isActive", "Active")}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{this.renderInput("firstName", "First name")}</Col>
                    <Col>{this.renderInput("lastName", "Last name")}</Col>
                    <Col>
                      <BaseSelectInput
                        name="cultureName"
                        label="Culture"
                        options={[
                          { name: "en", value: "en" },
                          { name: "nl", value: "nl" }
                        ]}
                        value={cultureName}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>{this.renderInput("email", "Email")}</Col>
                    <Col>
                      <FormGroup check>
                        {this.renderCheckbox( "emailConfirmed", "Email confirmed" )}
                      </FormGroup>
                    </Col>
                    <Col>{this.renderInput("phoneNumber", "Phone number")}</Col>
                  </Row>
                  <Row>
                    <Col>{this.renderInput("description", "Description")}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="text-muted">
                        created: {createDate} | modified: {modifiedDate} 
                      </span>
                      {unsubscribedDate &&
                        <div className="text-muted">
                        unsubscribed: {unsubscribedDate}
                      </div>
                      }
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row><Col>
                  <div>
                    <UserRolesInput
                      roles={roles}
                      userRoleNames={userRoleNames}
                      errors={this.state.errors}
                      onRolesChange={this.handleRolesChange}
                    />
                  </div>
                  </Col>
                  </Row>
                  {(this.state.notificationMethods && userNotificationMethods) &&
                  <Row className="mt-3"><Col>
                    <div>
                      <NotificationMethodsInput
                          notificationMethods={this.state.notificationMethods}
                          userNotificationMethods={userNotificationMethods}
                          errors={this.state.errors}
                          onNotificationMethodsChange={this.handleUserNotificationMethodsChange}
                      />
                    </div>
                  </Col>
                  </Row>
                  }
                  {onboardingState &&
                  <Row><Col className="mt-4">Onboarding state: {onboardingState}</Col></Row>
                  }
                  {idVerificationState && 
                  <Row><Col className="mt-4">Id verification state: {idVerificationState}</Col></Row>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col className="my-3">{this.renderButton("Save user details")}</Col>
          </Row>
        </Form>
    );
  }
}

export default UserForm;
