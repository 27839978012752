import React from 'react'
import { Col, Container, Form, Row } from 'reactstrap'
import BaseForm from '../common/baseForm'
import http from '../../services/httpService'
import Joi from 'joi-browser'
import { toast } from 'react-toastify'
import MatchedUsersTable from './matchedUsersTable'
import BaseEditor from '../common/baseEditor'
import { AdminPermission } from '../adminPermission'
import { testUrl } from '../../services/urlTestService'
import DefaultTableContainer from '../common/defaultTableContainer'
import ParsedHousesTable from './parsedHousesTable'

class ParsedHouseForm extends BaseForm {
  state = {
    data: {
      address: '',
      areStudentsAllowed: false,
      bedrooms: '',
      city: '',
      price: '',
      zipCode: '',
      size: '',
      url: '',
      text: '',
      type: '',
      createDate: '',
      furnishing: '',
      imageUrl: '',
      isSharingAllowed: 0,
      isParkingSpotOrGarage: false,
      isSeniorHousing: false,
      duplicateOfId: '',
      duplicateSource: '',
      incomeRequirements: null,
      parserDefinitionId: null,
      parserDefinitionName: null,
      latitude: null,
      longitude: null,
    },
    errors: {},
    states: [],
  }

  schema = {
    id: Joi.string(),
    address: Joi.string()
      .required()
      .label('address'),
    agentRunId: Joi.optional(),
    bedrooms: Joi.optional(),
    city: Joi.required(),
    duplicateOfId: Joi.required(),
    duplicateSource: Joi.optional(),
    imageUrl: Joi.required(),
    incomeRequirements: Joi.optional(),
    descriptionInfo: Joi.optional(),
    furnishing: Joi.required(),
    url: Joi.string()
      .required()
      .label('url'),
    zipCode: Joi.optional(),
    price: Joi.required(),
    size: Joi.required(),
    source: Joi.required(),
    createDate: Joi.string().optional(),
    modifiedDate: Joi.string().optional(),
    isSharingAllowed: Joi.number(),
    isSeniorHousing: Joi.optional(),
    isParkingSpotOrGarage: Joi.optional(),
    areStudentsAllowed: Joi.number(),
    text: Joi.optional(),
    type: Joi.optional(),
    latitude: Joi.optional(),
    longitude: Joi.optional(),
  }

  async componentDidMount () {
    const { data } = await http.get('parsedhouses/edit/' + this.props.match.params.id)
    this.setState({ data, isLoaded: true, })
  }

  doSubmit = async () => {
    try {
      const { data } = this.state
      await http.post('parsedhouses/edit', data)
      toast.success('parsed houses details saved')
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.username = ex.response.data
        this.setState({ errors })
      }
    }
  }

  testUrlClick = async (e) => {
    const { data } = this.state
    const result = await testUrl(data.url, data.parserDefinitionName)
    e.preventDefault()
    // show alert with result

    alert(JSON.stringify(result, null, 2))
  }

  checkGeolocationClick = async (e) => {
    const { data } = this.state
    const { data: result } = await http.get(`cities/geolocation?lat=${data.latitude}&lon=${data.longitude}`)
    e.preventDefault()
    alert(JSON.stringify(result, null, 2))
  }

  render () {
    return (
      <Container>
        <Form onSubmit={this.handleSubmit} className="mt-4">
          <Row>
            <Col>{this.renderInput('address', 'Address')}</Col>
            <Col>{this.renderInput('city', 'City')}</Col>
            <Col>{this.renderInput('price', 'Price')}</Col>
            <Col>{this.renderInput('size', 'Size')}</Col>
            <Col>{this.renderInput('bedrooms', 'Bedrooms')}</Col>
            <Col>{this.renderInput('zipCode', 'Postcode')}</Col>
          </Row>
          <Row>
            <Col>{this.renderInput('isSharingAllowed', 'Sharing (0 Unknown, 1 yes, 2 No)')}</Col>
            <Col>{this.renderInput('areStudentsAllowed', 'Students (0 Unknown, 1 yes, 2 No, 3 Only students)')}</Col>
            <Col>{this.renderInput('isSeniorHousing', 'Senior housing')}</Col>
            <Col>{this.renderInput('furnishing', 'Furnishing')}</Col>
            <Col>{this.renderInput('type', 'Type')}</Col>
            <Col>{this.renderInput('isParkingSpotOrGarage', 'Parking')}</Col>
          </Row>
          <Row>
            <Col className="my-1">
              <div className="input-group mb-3">
                <input
                  type="text" className="form-control"
                  aria-describedby="basic-addon2"
                  value={this.state.data.imageUrl}
                  readOnly={true}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <a target="_blank" rel="noreferrer" href={this.state.data.imageUrl}>
                      Image url
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col className="my-1">
              <div className="input-group mb-3">
                <input type="text" className="form-control"
                       aria-describedby="basic-addon2"
                       value={this.state.data.url}
                       readOnly={true}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <a target="_blank" rel="noreferrer" href={this.state.data.url}>Url</a>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <BaseEditor value={this.state.data.text}/>
            </Col>
          </Row>
          <Row>
            <Col className="my-3">Created: {this.state.data.createDate} |
              Source: {this.state.data.source} | <a
                href={`${window.location.origin}/parserdefinitions/${this.state.data.parserDefinitionId}`}>Parser</a> |
              <a
                href={`https://www.google.com/maps/place/${this.state.data.latitude},${this.state.data.longitude}`}> Coordinates </a>
            </Col>
          </Row>
          {this.state.data.duplicateOfId &&
            <Row>
              <Col className="my-3"><a
                href={`${window.location.origin}/parsedhouses/${this.state.data.duplicateOfId}`}>
                Duplicate from {this.state.data.duplicateSource} </a></Col>
            </Row>
          }
          <AdminPermission>
            <Row>
              <Col className="my-3">{this.renderButton('Save')}
                <button className="btn btn-secondary ml-2"
                        onClick={this.testUrlClick}> Fetch from Agent
                </button>
                <button className="btn btn-secondary ml-2"
                        onClick={this.checkGeolocationClick}> Check Geolocation
                </button>
              </Col>
              <Col className="my-3"></Col>
            </Row>
          </AdminPermission>
        </Form>
        {this.state.data.incomeRequirements &&
          <Row>
            <Col className="my-3">
              <h4>Income requirements</h4>
              {JSON.stringify(this.state.data.incomeRequirements, null, 2)}
            </Col>
          </Row>
        }
        {this.state.data.descriptionInfo &&
          <Row>
            <Col className="my-3">
              <h4>Description info</h4>
              {JSON.stringify(this.state.data.descriptionInfo, null, 2)}
            </Col>
          </Row>
        }
        <DefaultTableContainer>
          {this.state.data.duplicateOfId &&
            (<><h4>Duplicates</h4>
              <ParsedHousesTable
                title="Parsed houses"
                getUrl={() => 'parsedhouses/duplicates/' + this.props.match.params.id}
                defaultPageSize={5}
              /></>)
          }
        </DefaultTableContainer>
        <Row>
          <MatchedUsersTable
            title="Parsed houses"
            getUrl={() =>
              'matchedusers/parsedhouse/' + this.props.match.params.id
            }
            parsedHouseId={this.props.match.params.id}
          />
        </Row>
      </Container>
    )
  }
}

export default ParsedHouseForm