import React from "react";
import { Container, Row, Col, Form, Button } from "reactstrap";
import Joi from "joi-browser";
import BaseForm from "./../common/baseForm";
import http from "../../services/httpService";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

class UserSubscriptionForm extends BaseForm {
  state = {
    data: {
      id: null,
      userId: "",
      selectedSubscriptionId: "",
      type: "Custom",
      startDate: new Date(),
      endDate: moment()
        .add(1, "months")
        .toDate(),
      comments: ""
    },
    subscriptionsList: [],
    errors: {}
  };

  async componentDidMount() {
    const userSubscriptionId = this.props.match.params.id;

    const { data: subscriptionsList } = await http.get(
      "userSubscriptions/subscriptions"
    );

    const { data } = this.state;
    data.userId = this.props.match.params.userId;
    data.selectedSubscriptionId = subscriptionsList[0].value;
    this.setState({
      data,
      subscriptionsList: subscriptionsList
    });

    if (userSubscriptionId === "new") return;

    const { data: userSubscription } = await http.get(
      `userSubscriptions/${
        this.props.match.params.userId
      }/${userSubscriptionId}`
    );

    this.setState({
      data: this.mapToViewModel(userSubscription),
      subscriptionsList: subscriptionsList
    });
  }

  mapToViewModel(userSubscription) {
    return {
      id: userSubscription.id,
      userId: userSubscription.userId,
      selectedSubscriptionId: userSubscription.selectedSubscriptionId,
      startDate: new Date(userSubscription.startDate),
      endDate: new Date(userSubscription.endDate),
      comments: userSubscription.comments,
      type: userSubscription.type
    };
  }

  schema = {
    id: Joi.string()
      .allow("")
      .allow(null),
    userId: Joi.string(),
    selectedSubscriptionId: Joi.string(),
    startDate: Joi.date(),
    endDate: Joi.date(),
    type: Joi.string(),
    comments: Joi.string()
      .allow("")
      .allow(null)
  };

  doSubmit = async () => {
    await http.post("usersubscriptions", this.state.data);
    this.props.history.goBack();
  };


  handleDelete = async () => {
    const { id } = this.state.data;
    if (!id) return;

    // Show browser native confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete this subscription?");
    if (!confirmDelete) return;

    try {
      await http.delete(`usersubscriptions/${id}`);
      this.props.history.goBack();
    } catch (error) {
      console.error("Error deleting subscription:", error);
    }
  };

  render() {
    // const { userId } = this.state.data;
    return (
      <Container>
        <Row className="mt-4">
          <Col sm={{ size: 6, offset: 3 }}>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col>
                  {this.renderSelect(
                    "selectedSubscriptionId",
                    "Subscription",
                    this.state.subscriptionsList
                  )}
                </Col>
                <Col>{this.renderInputDate("startDate", "Start date")}</Col>
                <Col>{this.renderInputDate("endDate", "End date")}</Col>
              </Row>
              <Row>
                <Col>{this.renderInput("comments", "Comments")}</Col>
              </Row>
              <Row>
                <Col sm="2">{this.renderButton("Save")}</Col>
                <Col>
                  <Button onClick={this.props.history.goBack} color="warning">
                    Cancel
                  </Button>
                  </Col>
                  {this.state.data.id && (
                  <Col sm="2">
                    <Button onClick={this.handleDelete} color="danger" type="button">
                      Delete
                    </Button>
                  </Col>
                )}
                </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default UserSubscriptionForm;
