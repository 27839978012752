import React from 'react';

const HoveredOptionContext = React.createContext<{
  hoveredOptionValue: string[];
  setHoveredOptionValue: (optionValue: string[]) => void;
} | null>(null);

export function HoveredOptionProvider({ children }: React.PropsWithChildren<{}>) {
  const [value, setValue] = React.useState<string[]>([]);

  const setHoveredOptionValue = React.useCallback(
    (optionValue: string[]) => setValue(optionValue),
    []
  );

  const contextValue = React.useMemo(
    () => ({
      hoveredOptionValue: value,
      setHoveredOptionValue,
    }),
    [setHoveredOptionValue, value]
  );

  return (
    <HoveredOptionContext.Provider value={contextValue}>
      {children}
    </HoveredOptionContext.Provider>
  );
}

export function useHoveredOption() {
  const context = React.useContext(HoveredOptionContext);

  if (!context) {
    throw new Error(
      'useHoveredOption must be used within a HoveredOptionProvider'
    );
  }

  return context;
}
