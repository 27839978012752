import React, {Component} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import NavBar from "./components/navbar";
import Dashboard from "./components/dashboard/dashboard";
import ProtectedRoute from "./components/common/protectedRoute";
import LoginForm from "./components/loginForm";
import Logout from "./components/logout";
import http from "./services/httpService";
import auth from "./services/authService";
import "bootstrap/dist/css/bootstrap.min.css";
import UserSubscriptionForm from "./components/users/userSubscriptionForm";
import "react-toastify/dist/ReactToastify.css";
import UsersTable from "./components/users/usersTable";
import PaymentsTable from "./components/paymentsTable";
import PaymentDetails from "./components/paymentDetails";
import TestimonialsTable from "./components/testimonialsTable.";
import Parsers from "./components/parsers/parsers";
import FurnishingCriteria from "./components/furnishingCriteria";
import ParserDefinitionForm from "./components/parsers/parserDefinitionForm";
import ParsedHousesTable from "./components/parsedHouses/parsedHousesTable";
import ReportedProblems from "./components/reportedProblems/reportedProblems";
import ParsedHouseForm from "./components/parsedHouses/parsedHouseForm";
import DefaultTableContainer from "./components/common/defaultTableContainer";
import CustomerFeedbackTable from "./components/customerFeedback/customerFeedbackTable";
import CustomerFeedbackDetails from "./components/customerFeedback/customerFeedbackDetails";
import SearchProfileForm from "./components/searchProfileForm";
import HousesTable from "./components/houses/housesTable";
import HouseForm from "./components/houses/houseForm";
import Stats from "./components/stats";
import Cities from "./components/searchCriteria/cities";
import Postcodes from "./components/searchCriteria/postcodes";
import PostcodeForm from "./components/searchCriteria/postcodeForm";
import UserDetails from "./components/users/userDetails";
import CustomerServiceDashboard from "./components/dashboard/customerServiceDashboard";
import {AdminPermission, CustomerServicePermission} from "./components/adminPermission";
import Sources from "./components/sources/sources";
import EditSource from "./components/sources/editSource";
import AddSource from "./components/sources/addSource";
import Diagnostics from "./components/searchCriteria/diagnostics";
import DuplicateDetection from "./components/duplicateDetection/duplicateDetection";
import AnalyzeSource from "./components/sources/analyzeSource";
import { UsersList } from "./components/users/usersList";

class App extends Component {
    state = {
        data: [],
        isLoading: true,
    };

    componentDidMount() {
        const user = auth.getCurrentUser();
        this.setState({user});
    }

    handleLoad = async (endpoint) => {
        const {data} = await http.get(endpoint);
        this.setState({data, isLoading: false});
    };

    render() {
        const {user} = this.state;
        // alert(user);
        return (
            <BrowserRouter>
                <React.Fragment>
                    <ToastContainer/>
                    <NavBar user={user}/>
                    <main>
                        <Switch>
                            <ProtectedRoute path="/sources/analyze"
                                            render={(props) => <AnalyzeSource {...props} />}/>
                            <ProtectedRoute path="/sources/add"
                                            render={(props) => <AddSource {...props} />}/>
                            <ProtectedRoute path="/sources/edit/:id"
                                            render={(props) => <EditSource {...props} />}/>
                            <ProtectedRoute path="/sources" component={() => Sources()}/>
                            <ProtectedRoute
                                path="/users/:userId/searchprofiles/:id"
                                component={SearchProfileForm}
                            />
                            <ProtectedRoute
                                path="/users/:userId/usersubscriptions/:id"
                                component={UserSubscriptionForm}
                            />
                            <ProtectedRoute
                                path="/users/:id"
                                render={(props) => <UserDetails {...props} />}
                            />
                            <ProtectedRoute
                                path="/payments/:id"
                                render={(props) => <PaymentDetails {...props} />}
                            />
                            <ProtectedRoute
                                path="/users"
                                render={(props) => <UsersList {...props} />}
                            />
                            <ProtectedRoute
                                path="/payments"
                                render={() => (
                                    <DefaultTableContainer>
                                        <PaymentsTable endpoint="payments" title="Payments"/>
                                    </DefaultTableContainer>
                                )}
                            />
                            <ProtectedRoute
                                path="/parsedhouses/:id"
                                render={(props) => <ParsedHouseForm {...props} />}
                            />
                            <ProtectedRoute
                                path="/reportedproblems"
                                render={() => (
                                    <DefaultTableContainer>
                                        <ReportedProblems title="Reported problems"
                                                          getUrl={() => "reportedproblems"}
                                                          data={this.state.data}/>
                                    </DefaultTableContainer>
                                )}
                            />
                            <ProtectedRoute
                                path="/parsedhouses"
                                render={() => (
                                    <DefaultTableContainer>
                                        <ParsedHousesTable title="Parsed houses"
                                                           getUrl={() => "parsedhouses"}
                                                           data={this.state.data}/>
                                    </DefaultTableContainer>
                                )}
                            />
                            <ProtectedRoute
                                path="/parsers"
                                render={() => (
                                    <Parsers
                                        title="Parser definitions"
                                        onLoad={() => this.handleLoad("parerdefinitions")}
                                        data={this.state.data}
                                    />
                                )}
                            />
                            <ProtectedRoute path="/houses/:id" component={HouseForm}/>
                            <ProtectedRoute
                                path="/houses"
                                render={() => (
                                    <DefaultTableContainer>
                                        <HousesTable title="Houses" getUrl={() => "houses"}/>
                                    </DefaultTableContainer>
                                )}
                            />

                            <ProtectedRoute path="/diagnostics"
                                            component={(props) => Diagnostics(props)}/>
                            <ProtectedRoute path="/postcodes/:id" component={PostcodeForm}/>
                            <ProtectedRoute path="/postcodes"
                                            render={() => <Postcodes title="postcodes"/>}
                            />
                            <ProtectedRoute path="/cities" render={() => <Cities title="cities"/>}
                            />
                            <ProtectedRoute
                                path="/furnishingcriteria"
                                render={() => (
                                    <FurnishingCriteria
                                        title="Furnishing criteria"
                                        onLoad={() => this.handleLoad("furnishingcriteria")}
                                        data={this.state.data}
                                    />
                                )}
                            />
                            <ProtectedRoute
                                path="/parserdefinitions/:id"
                                render={(props) => <ParserDefinitionForm {...props} />}
                            />
                            <ProtectedRoute
                                path="/testimonials"
                                render={() => (
                                    <DefaultTableContainer>
                                        <TestimonialsTable
                                            title="testimonials"
                                            onLoad={() => this.handleLoad("testimonials")}
                                            data={this.state.data}
                                        />
                                    </DefaultTableContainer>
                                )}
                            />
                            <ProtectedRoute
                                path="/customerfeedback/:id"
                                render={(props) => <CustomerFeedbackDetails {...props} />}
                            />
                            <ProtectedRoute
                                path="/feedback"
                                render={() => (
                                    <DefaultTableContainer>
                                        <CustomerFeedbackTable
                                            title="customer feedback"
                                            onLoad={() => this.handleLoad("customerfeedback")}
                                            data={this.state.data}
                                        />
                                    </DefaultTableContainer>
                                )}
                            />
                            <ProtectedRoute
                                path="/statsperneighbourhood"
                                render={(props) => (
                                    <Stats url="stats/" {...props} />
                                )}
                            />
                            <ProtectedRoute
                                path="/stats"
                                render={(props) => <Stats url="stats" {...props} />}
                            />
                            <ProtectedRoute
                                path="/duplicates/:id"
                                render={(props) => <DuplicateDetection {...props} />}
                            />

                            <Route path="/login" component={LoginForm}/>
                            <Route path="/not-found" render={() => <h1>Not found</h1>}/>
                            <ProtectedRoute path="/logout" component={Logout}/>
                            <ProtectedRoute path="/" exact render={(props) =>
                                <React.Fragment>
                                    <AdminPermission>
                                        <Dashboard {...props}/>
                                    </AdminPermission>
                                    <CustomerServicePermission>
                                        <CustomerServiceDashboard/>
                                    </CustomerServicePermission>
                                </React.Fragment>
                            }/>
                            <Redirect to="/not-found"/>
                        </Switch>
                    </main>
                </React.Fragment>
            </BrowserRouter>
        );
    }
}

export default App;
