import React from "react";
import {Col, Container, Form, Row} from "reactstrap";
import http from "../../services/httpService";
import Joi from "joi-browser";
import {toast} from "react-toastify";
import ParsedHousesTable from "../parsedHouses/parsedHousesTable";
import DefaultTableContainer from "../common/defaultTableContainer";
import BaseForm from "../common/baseForm";

class ParserDefinitionForm extends BaseForm {
    state = {
        data: {
            name: "",
            state: "",
            isPaidSource: false,
            order: "",
            logsUrl: "",
            url: ""
        },
        errors: {},
        states: []
    };

    schema = {
        id: Joi.string(),
        name: Joi.string()
            .required()
            .label("name"),
        state: Joi.required(),
        isPaidSource: Joi.optional(),
        order: Joi.number(),
        createDate: Joi.string().optional(),
        modifiedDate: Joi.string().optional(),
        lastParsedHouseDate: Joi.optional(),
        logsUrl: Joi.string().optional(),
        url: Joi.string().optional()
    };

    async componentDidMount() {
        if (this.props.match.params.id !== "new") {
            const {data} = await http.get(
                "parserdefinitions/edit/" + this.props.match.params.id
            );
            this.setState({
                data
            });
        }
        const {data: states} = await http.get("parserdefinitions/states/");
        // console.log(data);
        this.setState({
            isLoaded: true,
            states
        });
    }

    doSubmit = async () => {
        try {
            const {data} = this.state;
            await http.post("parserdefinitions/edit", data);
            toast.success("parser definition details saved");
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                errors.username = ex.response.data;
                this.setState({errors});
            }
        }
    };

    render() {
        return (
            <React.Fragment>
                <Container>
                    <Form onSubmit={this.handleSubmit} className="mt-4">
                        <Row>
                            <Col>{this.renderInput("name", "Name")}</Col>
                            <Col>
                                {this.renderSelect("state", "State", this.state.states)}
                            </Col>
                            <Col>{this.renderInput("order", "Order (0 is last)")}</Col>
                            <Col>{this.renderCheckbox("isPaidSource", "is paid source")}</Col>
                        </Row>
                        <Row>
                            <Col className="my-3">
                                {this.renderButton("Save user details")}
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <DefaultTableContainer>
                    <ParsedHousesTable
                        title="Parsed houses"
                        getUrl={() => "parsedhouses/" + this.props.match.params.id}
                    />
                </DefaultTableContainer>
            </React.Fragment>
        );
    }
}

export default ParserDefinitionForm;
