import React from 'react'
import { Button, Col, Container, Form, FormGroup, Row } from 'reactstrap'
import Joi from 'joi-browser'
import TreeList from './common/treeList'
import http from '../services/httpService'
import { toast } from 'react-toastify'
import BaseForm from './common/baseForm'
import { NeighbourhoodsMultiSelect } from './common/neighbourhoods-multi-select'
import { Map } from './common/map'
import { HoveredOptionProvider } from '../contexts/hovered-option';

class SearchProfileForm extends BaseForm {
  state = {
    data: {
      id: null,
      locationSelectorList: [],
      name: null,
      userId: '',
      minPrice: 700,
      maxPrice: 1000,
      minPriceList: [],
      maxPriceList: [],
      sizeRangeList: [],
      bedroomsRangeList: [],
      furnishingList: [],
      cityList: [],
      radius: 0,
      radiusList: [],
      searchZipCodeIds: [],
      flexiblePrice: false,
      flexibleSizeRange: false,
      flexibleRoomRange: false,
      flexibleFurnishing: false,
      includePaidSources: false,
      allowedToShare: false,
      flexibleAllowedToShare: false,
      student: false,
      excludeSeniorHousing: false,
      excludeWoningNet: false,
      excludeKamernet: false,
      excludeRooms: false,
      netSalary: null,
      grossSalary: null,
      overrideLatitude: null,
      overrideLongitude: null,
      radiusInMeters: 0,
      locationIds: [],
      excludeShortTermRentals: false
    },
    tree: [],
    neighbourhoods: [],
    cityCoordinates: {
      lat: 0,
      lng: 0,
    },
    errors: {}
  }

  schema = {
    id: Joi.optional(),
    name: Joi.optional(),
    isActive: Joi.boolean(),
    flexiblePrice: Joi.boolean(),
    flexibleSharingNotAllowed: Joi.boolean(),
    flexibleSizeRange: Joi.boolean(),
    flexibleRoomRange: Joi.boolean(),
    flexibleFurnishing: Joi.boolean(),
    flexibleZipCodes: Joi.any(),
    includePaidSources: Joi.boolean(),
    netSalary: Joi.optional(),
    grossSalary: Joi.optional(),
    allowedToShare: Joi.boolean(),
    flexibleAllowedToShare: Joi.boolean(),
    student: Joi.boolean(),
    excludeSeniorHousing: Joi.boolean(),
    excludeWoningNet: Joi.boolean(),
    excludeKamernet: Joi.boolean(),
    excludeRooms: Joi.boolean(),
    radius: Joi.number().when('locationSelector', {
      is: 'Radius',
      then: Joi.number().positive().required(),
      otherwise: Joi.number(),
    }),
    radiusInMeters: Joi.number().optional(),
    radiusList: Joi.array(),
    locationSelector: Joi.string(),
    locationSelectorList: Joi.array(),
    minPrice: Joi.number().required(),
    maxPrice: Joi.number().required(),
    sizeRangeId: Joi.string().required(),
    cityId: Joi.string().required(),
    roomRangeId: Joi.string().required(),
    furnishingId: Joi.string().required(),
    minPriceList: Joi.array(),
    maxPriceList: Joi.array(),
    sizeRangeList: Joi.array(),
    cityList: Joi.array(),
    bedroomsRangeList: Joi.array(),
    furnishingList: Joi.array(),
    searchZipCodeIds: Joi.array()
      .label('Neighbourhoods (by postcodes)')
      .when('locationSelector', {
        is: 'Neighbourhood',
        then: Joi.array().min(1).required(),
        otherwise: Joi.array(),
      }),
    zipCodesTree: Joi.array(),
    createDate: Joi.string(),
    modifiedDate: Joi.string(),
    overrideLatitude: Joi.number().min(-90).max(90),
    overrideLongitude: Joi.number().min(-90).max(90),
    locationIds: Joi.array()
      .label('Neighbourhoods (by geolocation)')
      .when('locationSelector', {
        is: 'Geolocation',
        then: Joi.array().min(1).required(),
        otherwise: Joi.array(),
      }),
    locations: Joi.array(),
    userId: Joi.string(),
    excludeShortTermRentals: Joi.boolean()
  }

  async componentDidMount () {
    const searchProfileId = this.props.match.params.id

    const { data } = this.state
    data.userId = this.props.match.params.userId
    var urlTemplate = `search-profiles`
    if (searchProfileId !== 'new') {
      urlTemplate += `/${searchProfileId}`
    }

    const { data: searchProfile } = await http.get(urlTemplate)

    this.loadNeighbourhoods(searchProfile.cityId).then((neighbourhoods) => {
      this.setState({ neighbourhoods });
    });

    this.loadCityCoordinates(searchProfile.cityId).then((cityCoordinates) => {
      this.setState({ cityCoordinates });
    });

    const radiusInMeters = searchProfile.radius > 50 ? searchProfile.radius : 0

    const tree = JSON.parse(JSON.stringify(searchProfile.zipCodesTree))

    this.setState({ data: { ...searchProfile, radiusInMeters }, tree })
  }

  loadNeighbourhoods = async (cityId) => {
    return await http
      .get(`/cities/${cityId}/neighbourhoods`)
      .then((response) =>
        response.data.map((item) => ({
          ...item,
          type: 'Feature',
        }))
      )
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  loadCityCoordinates = async (cityId) => {
    return await http
      .get(`/cities/${cityId}/details`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return {
          lat: 0,
          lng: 0,
        };
      });
  }

  doSubmit = async () => {
    try {
      if (this.state.data.radiusInMeters > 50) {
        this.state.data.radius = this.state.data.radiusInMeters
      }
      const searchProfileId = this.props.match.params.id
      if (searchProfileId !== 'new') {
        await http.put(
          'search-profiles/' + searchProfileId,
          this.state.data
        )
      } else {
        await http.post(
          'search-profiles',
          { ...this.state.data, userId: this.props.match.params.userId }
        )
      }
      toast.success('search profile saved')
      this.props.history.goBack()
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors }
        errors.username = ex.response.data
        this.setState({ errors })
      }
    }
  }

  handleCityChange = async ({ currentTarget: input }) => {
    this.setState((previous) => ({
      ...previous,
      cityCoordinates: {
        lat: 0,
        lng: 0,
      },
      data: {
        ...previous.data,
        cityId: input.value,
        searchZipCodeIds: [],
        locationIds: [],
        overrideLatitude: 0,
        overrideLongitude: 0,
      },
    }));

    const { data: tree } = await http.get(
      'searchprofiles/zipcodes/' + input.value
    );

    this.setState({ tree });

    this.loadNeighbourhoods(input.value).then((neighbourhoods) => {
      this.setState({ neighbourhoods });
    });

    this.loadCityCoordinates(input.value).then((cityCoordinates) => {
      this.setState({ cityCoordinates });
    });
  }


  handleTreeChange = (searchZipCodeIds) => {
    const { data } = this.state
    data.searchZipCodeIds = searchZipCodeIds
    this.setState({ data })
  }

  handleDelete = async e => {
    e.preventDefault()

    if (window.confirm('do you want to delete this search profile?')) {
      await http.delete('searchprofiles/' + this.state.data.id)

      toast.success('search profile deleted')

      this.props.history.goBack()
    }
  }

  handleRadiusInMetersChange = ({ currentTarget: input }) => {
    const { data } = this.state
    data.radiusInMeters = input.value ? parseInt(input.value, 10) : null
    this.setState({ data })
  }

  handleRadiusChange = ({ currentTarget }) => {
    this.handleChange({ currentTarget });
    this.setState((previous) => ({
      data: {
        ...previous.data,
        radiusInMeters: 0,
      },
    }));
  };

  handleNeighbourhoodsChange = (nextLocationIds) => {
    this.setState({
      data: {
        ...this.state.data,
        locationIds: nextLocationIds,
      },
    });
  };

  handleMarkerChange = (marker) => {
    this.setState((previous) => ({
      data: {
        ...previous.data,
        overrideLatitude: marker.lat,
        overrideLongitude: marker.lng,
      },
    }));
  };

  render() {
    const {
      id,
      minPriceList,
      maxPriceList,
      sizeRangeList,
      bedroomsRangeList,
      furnishingList,
      cityList,
      searchZipCodeIds,
      radiusList,
      locationSelectorList,
      locationSelector,
    } = this.state.data;

    const errors = this.validate();

    const isOverrideCoordinatesValid =
      this.state.data.overrideLatitude &&
      this.state.data.overrideLongitude &&
      !errors?.['overrideLatitude'] &&
      !errors?.['overrideLongitude'];

    const markerCoordinates = {
      lat: isOverrideCoordinatesValid
        ? this.state.data.overrideLatitude
        : this.state.cityCoordinates.lat,
      lng: isOverrideCoordinatesValid
        ? this.state.data.overrideLongitude
        : this.state.cityCoordinates.lng,
    };

    return (
      <Container>
        <Form onSubmit={this.handleSubmit}>
          <Row className="mt-4">
            <Col>
              <Row>
                <Col sm="8">
                  <FormGroup row>
                    <Col>{this.renderInput('name', 'Name')}</Col>
                    <Col>
                      {this.renderSelect('minPrice', 'Min price', minPriceList)}
                    </Col>
                    <Col>
                      {this.renderSelect('maxPrice', 'Max price', maxPriceList)}
                    </Col>
                  </FormGroup>

                  <FormGroup row className="my-3">
                    <Col>
                      {this.renderSelect('sizeRangeId', 'Size', sizeRangeList)}
                    </Col>
                    <Col>
                      {this.renderSelect('roomRangeId', 'Room range', bedroomsRangeList)}
                    </Col>

                  </FormGroup>
                  <FormGroup row className="my-3">
                    <Col>
                      {this.renderSelect('furnishingId', 'Furnishing', furnishingList)}
                    </Col>
                    <Col>
                      {this.renderSelect('cityId', 'City', cityList, this.handleCityChange)}
                    </Col>
                  </FormGroup>
                </Col>
                <Col>
                <FormGroup check>
                    <Col>
                      {this.renderCheckbox(
                        'flexibleFurnishing',
                        'Flexible furnishing'
                      )}
                    </Col>
                    <Col>
                      {this.renderCheckbox(
                        'includePaidSources',
                        'Include PaidSources'
                      )}
                    </Col>
                    <Col>
                      {this.renderCheckbox(
                        'allowedToShare',
                        'Allowed to share'
                      )}
                    </Col>
                    <Col>
                      {this.renderCheckbox(
                        'flexibleAllowedToShare',
                        'Flexible allowed to share'
                      )}
                    </Col>
                    <Col>{this.renderCheckbox('student', 'Student')}</Col>
                    <Col>
                      {this.renderCheckbox(
                        'excludeSeniorHousing',
                        'Exclude 50+'
                      )}
                    </Col>
                    <Col>
                      {this.renderCheckbox(
                        'excludeWoningNet',
                        'Exclude WoningNet'
                      )}
                    </Col>
                    <Col>
                      {this.renderCheckbox(
                        'excludeKamernet',
                        'Exclude Kamernet'
                      )}
                    </Col>
                    <Col>
                      {this.renderCheckbox('excludeRooms', 'Exclude Rooms')}
                    </Col>
                    <Col>
                      {this.renderCheckbox('excludeShortTermRentals', 'Exclude Short Term Rentals')}
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
            <FormGroup row>
                <HoveredOptionProvider>
                  <Col md="6">
                    {this.renderSelect(
                      'locationSelector',
                      'Location selector (defines which method will be used for matching)',
                      locationSelectorList
                    )}
                    {locationSelector === 'Geolocation' && (
                      <NeighbourhoodsMultiSelect
                        values={this.state.data.locationIds}
                        onChange={this.handleNeighbourhoodsChange}
                        neighbourhoodsData={this.state.neighbourhoods}
                      />
                    )}
                    {locationSelector === 'Neighbourhood' && (
                      <>
                        {this.state.errors['searchZipCodeIds'] && (
                          <div className="alert alert-danger">
                            {this.state.errors['searchZipCodeIds']}
                          </div>
                        )}
                        {searchZipCodeIds && (
                          <div>
                            <label>Neighbourhoods (by postcodes)</label>
                            <TreeList
                              zipCodesTree={this.state.tree}
                              onTreeChange={this.handleTreeChange}
                              searchZipCodeIds={searchZipCodeIds}
                            />
                          </div>
                        )}
                      </>
                    )}
                    {locationSelector === 'Radius' && (
                      <>
                        {this.renderSelect(
                          'radius',
                          'Radius',
                          radiusList,
                          this.handleRadiusChange
                        )}
                        {this.renderInput(
                          'radiusInMeters',
                          'Radius in meters (overrides dropdown value)',
                          'number'
                        )}
                        <FormGroup row>
                          <Col>
                            {this.renderInput(
                              'overrideLatitude',
                              'override Latitude (radius center)',
                              'number'
                            )}
                            {this.state.data.overrideLatitude !== 0.0 &&
                              this.state.data.overrideLongitude !== 0.0 && (
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${this.state.data.overrideLatitude},${this.state.data.overrideLongitude}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Show override coordinates on map
                                </a>
                              )}
                          </Col>
                          <Col>
                            {this.renderInput(
                              'overrideLongitude',
                              'override Longitude (radius center)',
                              'number'
                            )}
                          </Col>
                        </FormGroup>
                      </>
                    )}
                  </Col>
                  <Col md="6">
                    <div style={{  height: '400px' }}>
                      <Map
                        locationSelector={this.state.data.locationSelector}
                        neighbourhoodsData={this.state.neighbourhoods}
                        locationIds={this.state.data.locationIds}
                        onMarkerChange={this.handleMarkerChange}
                        radiusKm={
                          this.state.data.radiusInMeters
                            ? this.state.data.radiusInMeters / 1000
                            : this.state.data.radius
                        }
                        cityCoordinates={this.state.cityCoordinates}
                        marker={markerCoordinates}
                      />
                    </div>
                  </Col>
                </HoveredOptionProvider>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>{this.renderInput('netSalary', 'Net salary', 'number')}</Col>
            <Col>{this.renderInput('grossSalary', 'Gross salary', 'number')}</Col>
          </Row>
          <Row>
            {errors && (
              <ul>
                {Object.values(errors).map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            )}
          </Row>
          <Row>
            <Col sm="1">{this.renderButton('Save')}</Col>
            <Col sm="1">
              <Button onClick={this.props.history.goBack}> Back </Button>
            </Col>
            {id && (
              <Col>
                <Button onClick={this.handleDelete} color="danger"> Delete </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Container>
    )
  }
}

export default SearchProfileForm
