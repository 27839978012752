import React, { useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { Button } from 'reactstrap';
import styles from './neighbourhoods-multi-select.module.css';
import { useHoveredOption } from '../../contexts/hovered-option';

function GroupHeading(props) {
  const checkboxRef = useRef();
  const { setHoveredOptionValue } = useHoveredOption();
  const { expandedGroups, setExpandedGroups } = props.selectProps;
  const value = props.selectProps.value || [];
  const isAllSelected = props.data.options.every((option) =>
    value.find((valueOption) => valueOption.value === option.value)
  );

  const isSomeSelected =
    !isAllSelected &&
    props.data.options.some((option) =>
      value.find((valueOption) => valueOption.value === option.value)
    );

  const isExpanded = expandedGroups.includes(props.data.label);

  const handleToggleExpanded = (event) => {
    event.stopPropagation();
    setExpandedGroups((prev) =>
      isExpanded
        ? prev.filter((group) => group !== props.data.label)
        : [...prev, props.data.label]
    );
  };

  const handleSelectGroup = () => {
    if (isAllSelected) {
      props.selectProps.onChange(
        value.filter(
          (option) => !props.data.options.find((i) => i.value === option.value)
        )
      );
    } else {
      props.selectProps.onChange([
        ...value,
        ...props.data.options.filter(
          (option) =>
            !value.find((valueOption) => valueOption.value === option.value)
        ),
      ]);
    }
  };

  useEffect(() => {
    if (!checkboxRef.current) return;
    checkboxRef.current.indeterminate = isSomeSelected;
  }, [isSomeSelected]);

  return (
    <components.GroupHeading
      {...props}
      className={styles.groupHeading}
      onClick={handleSelectGroup}
      data-group-selected={isAllSelected}
      onMouseEnter={() => {
        setHoveredOptionValue(props.data.options.map((option) => option.value));
      }}
      onMouseLeave={() => {
        setHoveredOptionValue([]);
      }}
    >
      <span style={{ display: 'flex', gap: '8px' }}>
        <input
          ref={checkboxRef}
          type="checkbox"
          checked={isAllSelected}
          onChange={() => {}}
        />
        {props.data.label}
      </span>
      <span onClick={handleToggleExpanded} className={styles.groupExpandToggle}>
        {isExpanded ? 'Collapse ▲' : 'Expand ▼'}
      </span>
    </components.GroupHeading>
  );
}

function Group(props) {
  return (
    <components.Group
      {...props}
      innerProps={{
        ...props.innerProps,
        className: styles.group,
        'data-group': true,
      }}
    />
  );
}

function ValueContainer(props) {
  const selectedCount = props.selectProps.value?.length || 0;

  return (
    <components.ValueContainer {...props}>
      {!!selectedCount && <>{selectedCount} neighbourhoods selected</>}
      {props.children}
    </components.ValueContainer>
  );
}

function Option(props) {
  const { setHoveredOptionValue } = useHoveredOption();

  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        className: styles.option,
        onMouseEnter: () => {
          setHoveredOptionValue([props.data.value]);
        },
        onMouseLeave: () => {
          setHoveredOptionValue([]);
        },
        'data-group-option': props.data.isGroupOption,
        'data-hidden': props.data.isHidden,
      }}
    />
  );
}

function MultiValue() {
  return null;
}

const multiSelectComponents = {
  Option: Option,
  ValueContainer: ValueContainer,
  Group: Group,
  GroupHeading: GroupHeading,
  MultiValue: MultiValue,
};

function MultiSelect({ options, values, onChange }) {
  const [expandedGroups, setExpandedGroups] = React.useState([]);

  const nextOptions = options.map((optionOrGroup) => {
    if ('options' in optionOrGroup) {
      return {
        ...optionOrGroup,
        options: optionOrGroup.options.map((option) => {
          return {
            ...option,
            isHidden: !expandedGroups.includes(optionOrGroup.label),
            isGroupOption: true,
          };
        }),
      };
    }

    return optionOrGroup;
  });

  const selectedOptions = nextOptions.flatMap((optionOrGroup) => {
    if ('options' in optionOrGroup) {
      return optionOrGroup.options.filter((option) =>
        values.includes(option.value)
      );
    }

    return values.includes(optionOrGroup.value) ? optionOrGroup : [];
  });

  const { setHoveredOptionValue } = useHoveredOption();

  const handleMenuClose = () => {
    setHoveredOptionValue([]);
  };

  const isAllSelected = values.length === options.length;

  const handleSelectOrDeselectAll = () => {
    if (isAllSelected) {
      onChange([]);
    } else {
      onChange(options);
    }
  };

  return (
    <div>
      <div className={styles.label}>
        <label htmlFor="neighbourhoods-select">
          Neighbourhoods (by geolocation)
        </label>
        <Button
          color="link"
          type="button"
          size="sm"
          onClick={handleSelectOrDeselectAll}
        >
          {isAllSelected ? 'Deselect all' : 'Select all'}
        </Button>
      </div>
      <Select
        inputId="neighbourhoods-select"
        hideSelectedOptions={false}
        isMulti
        isSearchable={false}
        closeMenuOnSelect={false}
        isClearable={false}
        options={nextOptions}
        expandedGroups={expandedGroups}
        setExpandedGroups={setExpandedGroups}
        components={multiSelectComponents}
        value={selectedOptions}
        onChange={onChange}
        onMenuClose={handleMenuClose}
      />
    </div>
  );
}

function convertNeighbourhoodsToOptions(neighbourhoods) {
  return Object.entries(
    neighbourhoods.reduce(
      (acc, option) => {
        const { group, districtName, districtCode } = option.properties;
        if (!group) {
          acc.nonGroups.push({
            label: districtName,
            value: districtCode,
          });
          return acc;
        }

        if (!acc.groups[group]) {
          acc.groups[group] = { label: group, options: [] };
        }

        acc.groups[group].options.push({
          label: districtName,
          value: districtCode,
        });

        return acc;
      },
      {
        groups: {},
        nonGroups: [],
      }
    )
  ).flatMap(([key, value]) => {
    if (key === 'nonGroups') {
      return value;
    }

    return Object.values(value);
  });
}

export function NeighbourhoodsMultiSelect({
  values,
  onChange,
  neighbourhoodsData,
}) {
  const options = convertNeighbourhoodsToOptions(neighbourhoodsData);

  const handleChange = (nextOptions) => {
    onChange(nextOptions.map((option) => option.value));
  };

  return (
    <MultiSelect options={options} values={values} onChange={handleChange} />
  );
}
